import {merge} from 'lodash-es'
import PropTypes from 'prop-types'
import {ThemeProvider as StyledThemeProvider} from 'styled-components'
import GlobalStyles from './GlobalStyles'
import theme from './theme'


const ThemeProvider = ({color, children, ...props}) => {
  const newTheme = merge({}, theme, {colors: {primary: color || theme.colors.primary}})
  return (
    <StyledThemeProvider theme={newTheme} {...props}>
      <GlobalStyles />
      {children}
    </StyledThemeProvider>
  )
}

ThemeProvider.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
}

export default ThemeProvider
