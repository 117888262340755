import {transparentize, rgba} from 'polished'


const BLUE = '#0079FD'
const WHITE = '#FFFFFF'
const BLACK = '#000000'
const GREY_DARK = '#707070'
const GRAY = '#8F8F8F'
const GREEN = '#00FF00'
const RED = '#FF0000'

const GRADIENT_FROM = rgba(WHITE, 0.0)
const GRADIENT_TO = WHITE

// Fonts
const FONT_SIZE_BASE = 13
const FONT_SIZE_MOBILE = 11

export const px2rem = (size, base = FONT_SIZE_BASE) => `${size / base}rem`

const theme = {
  spacer: px2rem(8),
  colors: {
    primary: BLUE,
    secondary: GREY_DARK,
    secondary2: GRAY,
    text: BLACK,
    textInverted: WHITE,
    background: WHITE,
    positive: GREEN,
    negative: RED,
  },
  overlays: {
    background: transparentize(0.5, BLACK),
    backgroundNoBlur: transparentize(0.3, BLACK),
    backgroundNoBlurCookieBar: transparentize(0.125, BLACK),
    gradient: `${GRADIENT_FROM} 40%, ${GRADIENT_TO} 100%`,
    cookieBarGradient: `${GRADIENT_FROM} 0%, ${GRADIENT_TO} 100%`,
  },
  fonts: {
    family: 'Montserrat',
    size: {
      xxs: px2rem(14),
      xs: px2rem(16),
      sm: px2rem(18),
      md: px2rem(20),
      lg: px2rem(24),
      xl: px2rem(32),
      xxl: px2rem(35),
    },
    weight: {
      regular: '300',
      bold: '600',
    },
  },
  responsive: {
    breakpoints: {
      mobile: 768,
      tablet: 992,
      desktop: 1440,
    },
    zoom: {
      minimalViewport: 320,
      maximalViewport: 1441,
      fontSizeBase: FONT_SIZE_BASE,
      fontSizeMobile: FONT_SIZE_MOBILE,
    },
  },
  animation: '.2s ease-in',
  border: {
    default: '1px solid',
    radius: {
      sm: px2rem(3),
      md: px2rem(15),
      lg: px2rem(45),
      xl: '50%',
    },
  },
  backdropBlur: 'blur(15px)',
}

export default theme
