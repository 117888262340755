import AnalyticsProvider from '../hooks/useConsent'
import ThemeProvider from '../theme/ThemeProvider'


export const wrapPageElement = ({element, props: {pageContext: {color, gaKey, metaPixelId, gdpr}}}) => {
  return (
    <AnalyticsProvider gaKey={gaKey} metaPixelId={metaPixelId} gdpr={gdpr}>
      <ThemeProvider color={color}>
        {element}
      </ThemeProvider>
    </AnalyticsProvider>
  )
}
