import {createGlobalStyle, css} from 'styled-components'
import {normalize} from 'styled-normalize'
import typography from '../visual/typography/typography'
import media from '../utils/media'


export default createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
  }

  /* stylelint-disable */
  ${normalize}
  /* stylelint-enable */

  :root {
    scroll-behavior: smooth;
    font-family: sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    Liberation Sans, sans-serif;
    font-size: ${({theme}) => theme.responsive.zoom.fontSizeBase}px;

    /* Devices too small */
    ${({theme}) => media.down(theme.responsive.zoom.minimalViewport)(css`
      font-size: ${theme.responsive.zoom.fontSizeMobile / (theme.responsive.zoom.minimalViewport) * 100}vw;
    `)}

    /* Mobile */
    ${({theme}) => media.between(theme.responsive.zoom.minimalViewport, 'mobile')(css`
      font-size: ${theme.responsive.zoom.fontSizeMobile}px;
    `)}

    /* Desktop and larger */
    ${({theme}) => media.up('mobile')(css`
      @media only screen and (min-resolution: 96dpi) {
        font-size: ${(theme.responsive.zoom.fontSizeBase / theme.responsive.zoom.designedViewport) * 100}vw;
      }
      @media only screen and (-webkit-min-device-pixel-ratio: 1.33) {
        font-size: ${(theme.responsive.zoom.fontSizeBase / theme.responsive.zoom.designedViewport) * 100}vw;
      }
    `)}
  }

  body {
    font-size: 1rem;
    color: ${({theme}) => theme.colors.text};
  }

  a {
    color: ${({theme}) => theme.colors.primary};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  p:not(:first-child) {
    margin-block-start: ${({theme}) => theme.spacer};
    margin-block-end: 0;
  }

  ${typography}
`
