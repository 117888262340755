import {css} from 'styled-components'


export const body = css`
  line-height: 1.5;
  font-size: ${({theme}) => theme.fonts.size.xs};
  font-weight: ${({theme}) => theme.fonts.weight.regular};
`

export const h1 = css`
  line-height: 1.2;
  font-size: ${({theme}) => theme.fonts.size.xxl};
  font-weight: ${({theme}) => theme.fonts.weight.bold};
`

export const h2 = css`
  line-height: 1.5;
  font-size: ${({theme}) => theme.fonts.size.xl};
  font-weight: ${({theme}) => theme.fonts.weight.bold};
`

export const h3 = css`
  line-height: 1.5;
  font-size: ${({theme}) => theme.fonts.size.lg};
  font-weight: ${({theme}) => theme.fonts.weight.bold};
`

export const h4 = css`
  line-height: 1.5;
  font-size: ${({theme}) => theme.fonts.size.sm};
  font-weight: ${({theme}) => theme.fonts.weight.bold};
`

export const h5 = css`
  line-height: 1.5;
  font-size: ${({theme}) => theme.fonts.size.xs};
  font-weight: ${({theme}) => theme.fonts.weight.bold};
`

export const h6 = css`
  line-height: 1.5;
  font-size: ${({theme}) => theme.fonts.size.xxs};
  font-weight: ${({theme}) => theme.fonts.weight.regular};
`

export const button = css`
  line-height: 1.2;
  font-size: ${({theme}) => theme.fonts.size.md};
  font-weight: ${({theme}) => theme.fonts.weight.bold};
`

export const link = css`
  line-height: 1.5;
  font-size: ${({theme}) => theme.fonts.size.xxs};
  font-weight: ${({theme}) => theme.fonts.weight.regular};
`

export const input = css`
  line-height: 1.5;
  font-size: ${({theme}) => theme.fonts.size.xs};
  font-weight: ${({theme}) => theme.fonts.weight.bold};
`

export default css`
  body {
    ${body}
  }

  h1 {
    ${h1}
  }

  h2 {
    ${h2}
  }

  h3 {
    ${h3}
  }

  h4 {
    ${h4}
  }

  h5 {
    ${h5}
  }

  h6 {
    ${h6}
  }

  button {
    ${button}
  }

  a {
    ${link}
  }

  input,
  label {
    ${input}
  }

  strong {
    font-weight: ${({theme}) => theme.fonts.weight.bold};
  }

  i {
    font-style: italic;
  }

  p {
    padding: ${({theme}) => `calc(${theme.spacer} * 4) calc(${theme.spacer} * 2.25)`};
  }
`
